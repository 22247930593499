import React from 'react'
import classNames from 'classnames'
import SEO from '../../General/seo'
import PrismicPreview from '../../General/prismicpreview'

const PageWrap = ({
  seo = {},
  children,
  disableVerticalPadding,
  darkMode = false,
  className = '',
  disablePadding = false,
}) => {
  return (
    <>
      <SEO {...seo} />
      <PrismicPreview />
      <div
        className={classNames(
          'flex-grow',
          {
            'px-3 xl:p-0': disableVerticalPadding && !disablePadding,
            'p-3 xl:p-0': !disableVerticalPadding && !disablePadding,
            'bg-black': darkMode,
            ['p-0 xl:p-0']: disablePadding,
          },
          className
        )}
        id={`site-content`}
      >
        {children}
      </div>
    </>
  )
}

export default PageWrap
