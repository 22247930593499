import React from 'react'
import Helmet from 'react-helmet'

function preview () {
  return (
    <Helmet>
      <script async defer src="//static.cdn.prismic.io/prismic.js?repo=algorandcom&new=true"></script>
    </Helmet>
  )
}

export default preview
