import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

// favicons
import favicon48 from '../../images/favicons/icon-48x48.png'
import favicon72 from '../../images/favicons/icon-72x72.png'
import favicon96 from '../../images/favicons/icon-96x96.png'
import favicon144 from '../../images/favicons/icon-144x144.png'
import favicon256 from '../../images/favicons/icon-256x256.png'
import favicon384 from '../../images/favicons/icon-384x384.png'
import favicon512 from '../../images/favicons/icon-512x512.png'

// OG Image
import ogImage from '../../images/algorand-og-image.jpg'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            defaultTitle={data.site.siteMetadata.title}
            titleTemplate={
              title
                ? `%s | ${data.site.siteMetadata.title}`
                : data.site.siteMetadata.title
            }
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:image`,
                content: ogImage,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: `https://www.algorand.com${ogImage}`,
              },
              {
                name: 'referrer',
                content: 'origin-when-cross-origin',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
            link={[
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '48x48',
                href: `${favicon48}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '72x72',
                href: `${favicon72}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '96x96',
                href: `${favicon96}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '144x144',
                href: `${favicon144}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '256x256',
                href: `${favicon256}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '384x384',
                href: `${favicon384}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '512x512',
                href: `${favicon512}`,
              },
              {
                rel: 'shortcut icon',
                type: 'image/png',
                sizes: '72x72',
                href: `${favicon72}`,
              },
            ]}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

export const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
